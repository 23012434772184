import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Alert,
  AlertTitle,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
// import MuiPhoneNumber from "material-ui-phone-number";
import { MuiTelInput } from "mui-tel-input";

const SignUp = () => {
  const navigate = useNavigate();

  const [sites, setSites] = useState([]);
  const [birthDay] = useState(null);
  const [birthMonth] = useState(null);
  const [birthYear] = useState(null);
  const [assistedByStaff, setAssistedByStaff] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGenderError, setIsGenderError] = useState(false);
  const [mobilisationSessions, setMobilisationSessions] = useState([]);

  const [formData, setFormData] = useState({
    cf_hubs_id: "",
    firstname: "",
    lastname: "",
    cf_gender: "",
    birthday: "",
    mobile: "",
    email: "",
    cf_captured_by: "",
    cf_portal_source: "Safe-Hub Portal",
    assigned_user_id: "20x1", //need to setup a default webservice/portal user here
    cf_portal_pin: "",
    portal: "1"
  });
  const currentYear = new Date().getFullYear() - 17;
  const oldestAllowedYear = currentYear - 100;

  const validateGender = () => {
    if (!formData.cf_gender) {
      setIsGenderError(true);
      return false;
    } else {
      setIsGenderError(false);
      return true;
    }
  };

  useEffect(() => {
    if (formData.cf_hubs_id) {
      fetchMobilisationSessions(formData.cf_hubs_id);
    }
  }, [formData.cf_hubs_id]);

  const fetchMobilisationSessions = async (siteId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/getMobilisations/S@f3Hub123@/${siteId}`
      );
      setMobilisationSessions(response.data);
    } catch (error) {
      console.error("Error fetching mobilisation sessions:", error);
    }
  };

  const [iscf_captured_byError, setIscf_captured_byError] = useState(false);
  const [cf_captured_byValidationMessage, setcf_captured_byValidationMessage] =
    useState("");
  // Function to validate cf_captured_by
  const validatecf_captured_by = async () => {
    if (formData.cf_captured_by) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/checkStaff/S@f3Hub123@/${formData.cf_captured_by}`
        );
        // Assuming the response will be an empty array if the ID does not exist
        if (response.data && response.data.length === 0) {
          setIscf_captured_byError(true);
          setcf_captured_byValidationMessage(
            "The Employee ID added does not exist. Please check and try again."
          );
          return false;
        } else {
          setIscf_captured_byError(false);
          setcf_captured_byValidationMessage("");
          return true;
        }
      } catch (error) {
        console.error("There was an error validating the Employee ID", error);
        setIscf_captured_byError(true);
        setcf_captured_byValidationMessage(
          "The Employee ID added does not exist. Please check and try again."
        );
        return false;
      }
    } else {
      // If cf_captured_by is not provided, we assume it's valid (since it's optional)
      setIscf_captured_byError(false);
      setcf_captured_byValidationMessage("");
      return true;
    }
  };

  const generatePin = () => {
    return Math.floor(10000 + Math.random() * 90000).toString();
  };
  const checkPinUnique = async (pin) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/checkPin/S@f3Hub123@/${pin}`
      );
      // If the returned array is empty, then the PIN is unique
      return response.data.length === 0;
    } catch (error) {
      console.error("Error checking PIN:", error);
      return false; // assume not unique in case of an error
    }
  };

  const generateUniquePin = useCallback(async () => {
    let pin;
    let isUnique = false;
    while (!isUnique) {
      pin = generatePin();
      isUnique = await checkPinUnique(pin);
    }
    setFormData((prevData) => ({
      ...prevData,
      cf_portal_pin: pin,
    }));
  }, []);

  useEffect(() => {
    generateUniquePin();
  }, [generateUniquePin]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));

    // If the changed field is cf_hubs_id, update assigned_user_id
    if (name === 'cf_hubs_id') {
      const selectedSite = sites.find(site => "38x" + site.hubsid === value);
      if (selectedSite) {
        setFormData(prevData => ({
          ...prevData,
          assigned_user_id: "20x" + selectedSite.smownerid,
        }));
      }
      console.log(formData);
    }
  };

  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
  
    // Clear previous error messages
    setFeedbackMessage("");
    setIsGenderError(false);
    setIscf_captured_byError(false);
  
    // Validate gender before proceeding with the form submission
    if (!validateGender()) {
      setFeedbackMessage("Please select a gender.");
      setIsSubmitting(false);
      return;
    }
  
    const iscf_captured_byValid = await validatecf_captured_by();
    if (!iscf_captured_byValid) {
      setIsSubmitting(false);
      return; // Stop the form submission if the cf_captured_by is invalid
    }
  
    const payload = {
      module: "Contacts",
      data: formData,
    };
  
    if (!formData.cf_gender) {
      setFeedbackMessage("Please select a gender.");
      setIsSubmitting(false);
      return;
    }
  
    // Combine birth day, month, and year into a single value
    const dob = `${formData.birthYear}-${String(formData.birthMonth).padStart(2, "0")}-${String(formData.birthDay).padStart(2, "0")}`;
    payload.data.birthday = dob;
  
    try {
      // Check if mobile number exists
      const mobileResponse = await axios.post(`${process.env.REACT_APP_EXPRESS_SERVER_URL}/query/0`, {
        fields: "mobile",
        module: "Contacts",
        rules: `mobile='${payload.data.mobile}'`,
      });
  
      if (mobileResponse.data && mobileResponse.data.total_records > 0) {
        setFeedbackMessage("A user with this cell phone number already exists! Go to sign in page and use the 'Don't know my pin' option.");
        setIsSubmitting(false);
        return;
      }
  
      // Check if email exists (only if email is provided)
      if (payload.data.email) {
        const emailResponse = await axios.post(`${process.env.REACT_APP_EXPRESS_SERVER_URL}/query/0`, {
          fields: "email",
          module: "Contacts",
          rules: `email='${payload.data.email}'`,
        });
  
        if (emailResponse.data && emailResponse.data.total_records > 0) {
          setFeedbackMessage("A user with this email already exists! Go to sign in page and use the 'Don't know my pin' option.");
          setIsSubmitting(false);
          return;
        }
      }
  
      // If both checks pass, proceed with sign up
      const signupResponse = await axios.post(`${process.env.REACT_APP_EXPRESS_SERVER_URL}/create`, payload);
  
      if (signupResponse && signupResponse.data) {
        // Handle success
        setFeedbackMessage("User created successfully");
        navigate("/signup-success", {
          state: { pin: signupResponse.data.cf_portal_pin },
        });
      } else {
        // Handle other responses from the server
        setFeedbackMessage("There was an issue: " + signupResponse.data.message);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("There was an error processing the sign up", error);
      setFeedbackMessage("An error occurred during sign up. Please try again.");
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SAFEHUB_BMS_URL}/webservices/getSites/S@f3Hub123@`
    )
      .then((response) => response.json())
      .then((data) => setSites(data))
      .catch((err) => console.error("Failed fetching sites:", err));
  }, []);

  useEffect(() => {
    if (birthDay && birthMonth && birthYear) {
      setFormData((prevState) => ({
        ...prevState,
        birthday: `${birthYear}-${String(birthMonth).padStart(2, "0")}-${String(
          birthDay
        ).padStart(2, "0")}`,
      }));
    }
  }, [birthDay, birthMonth, birthYear]);

  return (
    <Container>
      <Box mt={4} display="flex" flexDirection="column" alignItems="center">
        <img src="/assets/images/logo.svg" alt="Logo" width="150" />
        <Typography variant="h5" gutterBottom>
          SIGN UP
        </Typography>
        <Typography variant="body1" paragraph>
          Complete the form below and click Sign Up. Once completed, you will
          receive your unique pin to login to your profile.
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box mt={2} width="100%">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Which Site</InputLabel>
              <Select
                name="cf_hubs_id"
                value={formData.cf_hubs_id}
                onChange={handleInputChange}
                label="Which Site"
                required
              >
                {sites.map((site) => (
                  <MenuItem key={site.hubsid} value={"38x" + site.hubsid}>
                    {site.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box mt={2}>
              <TextField
                fullWidth
                variant="outlined"
                label="First Name"
                name="firstname"
                value={formData.firstname}
                onChange={handleInputChange}
                required
              />
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                variant="outlined"
                label="Last Name"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                required
              />
            </Box>

            <Box mt={2}>
              <FormControl required error={isGenderError} component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  row
                  name="cf_gender"
                  value={formData.cf_gender}
                  onChange={handleInputChange}
                  required
                >
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="Prefer to self identify"
                    control={<Radio />}
                    label="Prefer to self identify"
                  />
                </RadioGroup>
              </FormControl>
              {isGenderError && (
                <FormHelperText>Please select your gender.</FormHelperText>
              )}
            </Box>

            {/* Date of Birth dropdowns */}
            <Box mt={2}>
              <FormControl
                variant="outlined"
                style={{ marginRight: 10, width: "calc(33% - 10px)" }}
              >
                <InputLabel>Day</InputLabel>
                <Select
                  name="birthDay"
                  value={formData.birthDay}
                  onChange={handleInputChange}
                  required
                >
                  {/* You can generate day numbers programmatically */}
                  {[...Array(31)].map((_, i) => (
                    <MenuItem key={i + 1} value={i + 1}>
                      {i + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                style={{ marginRight: 10, width: "calc(33% - 10px)" }}
              >
                <InputLabel>Month</InputLabel>
                <Select
                  name="birthMonth"
                  value={formData.birthMonth}
                  onChange={handleInputChange}
                  required
                >
                  <MenuItem value={1}>January</MenuItem>
                  <MenuItem value={2}>February</MenuItem>
                  <MenuItem value={3}>March</MenuItem>
                  <MenuItem value={4}>April</MenuItem>
                  <MenuItem value={5}>May</MenuItem>
                  <MenuItem value={6}>June</MenuItem>
                  <MenuItem value={7}>July</MenuItem>
                  <MenuItem value={8}>August</MenuItem>
                  <MenuItem value={9}>September</MenuItem>
                  <MenuItem value={10}>October</MenuItem>
                  <MenuItem value={11}>November</MenuItem>
                  <MenuItem value={12}>December</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" style={{ width: "33%" }}>
                <InputLabel>Year</InputLabel>
                <Select
                  name="birthYear"
                  value={formData.birthYear}
                  onChange={handleInputChange}
                  required
                >
                  {[...Array(currentYear - oldestAllowedYear)].map(
                    (_, index) => {
                      const year = currentYear - index;
                      return (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Box>

            <Box mt={2}>
              <MuiTelInput
                name="mobile"
                label="Your cell phone number"
                data-cy="user-phone"
                onlyCountries={["ZA", "US", "GB", "DE"]}
                defaultCountry={"ZA"}
                autoComplete="Off"
                countryCodeEditable={false}
                value={formData.mobile}
                onChange={(value) =>
                  setFormData({ ...formData, mobile: value })
                }
                variant="outlined"
                required
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                variant="outlined"
                label="Email"
                name="email"
                type="email"
                placeholder="example@email.com"
                value={formData.email}
                onChange={handleInputChange}
              />
            </Box>
            <Box mt={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Where Did You Hear About Us?</InputLabel>
                <Select
                  name="source"
                  value={formData.source}
                  onChange={handleInputChange}
                  label="Where Did You Hear About Us?"
                  required
                >
                  <MenuItem value="From School">From School</MenuItem>
                  <MenuItem value="Radio">Radio</MenuItem>
                  <MenuItem value="Friends">Friends</MenuItem>
                  <MenuItem value="Flyer">Flyer</MenuItem>
                  <MenuItem value="Social Media">Social Media</MenuItem>
                  <MenuItem value="Website">Website</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {formData.cf_hubs_id && (mobilisationSessions.length > 0) && (
              <Box mt={2} width="100%">
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Mobilisation Session</InputLabel>
                  <Select
                    name="cf_1294"
                    value={formData.cf_1294}
                    onChange={handleInputChange}
                    label="Mobilisation Session"
                  >
                    {mobilisationSessions.map((session) => (
                      <MenuItem
                        key={session.offeringsessionsid}
                        value={session.offeringsessionsid}
                      >
                        {`${session.cf_sessiontitle} - ${session.cf_session_date}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={assistedByStaff}
                    onChange={(e) => setAssistedByStaff(e.target.checked)}
                    name="assistedByStaff"
                  />
                }
                label="Are you being assisted by a Hub Staff Member?"
              />
              {assistedByStaff && (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Employee ID"
                  name="cf_captured_by"
                  value={formData.cf_captured_by}
                  onChange={handleInputChange}
                  error={iscf_captured_byError}
                  helperText={
                    iscf_captured_byError ? cf_captured_byValidationMessage : ""
                  }
                  required={assistedByStaff}
                />
              )}
            </Box>
            

            <Box mt={2}>
              {feedbackMessage && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {feedbackMessage}
                </Alert>
              )}
            </Box>

            <Box
              mt={2}
              display="flex"
              justifyContent="space-between"
              width="100%"
            >
              <Button
                variant="outlined"
                color="secondary"
                component={Link}
                to="/"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
              {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Sign Up"
                )}
              </Button>
            </Box>
            <Box mt={1}>
              <Typography variant="body2">
                By signing up to our portal you are in agreement with our{" "}
                <a
                  href="https://www.amandla.net/en/contact/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                &{" "}
                <a
                  href="https://www.amandla.net/en/contact/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
              </Typography>
            </Box>
          </Box>
        </form>
        <Box mt={4}>
          <Typography variant="body2">Safe-Hub Portal © 2023</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default SignUp;
